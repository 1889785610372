<template>
  <div>
    <div class="banner">
      <!-- <img
        src="../../../public/img/customService/caseDetailsBanner.jpg"
        alt=""
      /> -->
      <div>
        <h3>{{ caseCategoryName }}</h3>
        <p>{{ detailIntroduction }}</p>
      </div>
    </div>
    <div class="contBox1">
      <div class="leftImg">
        <el-carousel height="450px">
          <el-carousel-item v-for="item in detailPicture" :key="item">
            <!-- <img src="../../../public/img/customService/test2.jpg" alt="" /> -->
            <!-- <img :src="item.url" alt="" /> -->
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="rightText">
        <h3>{{ describe }}</h3>
        <p>{{ introduction }}</p>
      </div>
    </div>
    <div class="contBox2">
      <h2>主要功能</h2>
      <div>
        <div v-html="mainFunction"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { findCaseDetails } from '../../api/parentPage'
export default {
  name: '客户案例详情',
  data() {
    return {
      caseCategoryName: '案例详情1', //banner文本标题
      detailIntroduction: '案例详情1的描述信息', //banner详细描述
      describe: '第一案例', //案例名称
      introduction: '第一案例的详细介绍',
      detailPicture: [1, 2, 3], //轮播图
      mainFunction: `<p>这是主要内容的区域</p>`
    }
  },
  created() {
    this._initData()
  },
  methods: {
    _initData() {
      findCaseDetails({ id: this.$route.params.id }).then((res) => {
        if (res.status == 200) {
          console.log('findCaseDetails', res.data)
          // this.describe = res.data.name;
          // this.introduction = res.data.introduction;
          // this.mainFunction = res.data.mainFunction;
          // this.detailPicture = res.data.detailPicture.split(",");
          // this.caseCategoryName = res.data.caseCategoryName;
          // this.detailIntroduction = res.data.detailIntroduction;
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.banner {
  width: 100%;
  position: relative;
  img {
    width: 100%;
    height: 711px;
  }
  > div {
    position: absolute;
    left: 360px;
    bottom: 200px;
    text-align: start;
    > h3 {
      color: #484848;
      font-weight: 700;
      font-size: 46px;
      line-height: 56px;
    }
    > p {
      width: 690px;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      color: #ffffff;
    }
  }
}
.contBox1 {
  width: 1200px;
  height: 450px;
  margin: auto;
  margin-top: 80px;
  display: flex;
  justify-content: space-between;
  > .leftImg {
    width: 574px;
    height: 450px;
    .el-carousel {
      border-radius: 20px;
      .el-carousel__item {
        text-align: center;
        img {
          height: 450px;
        }
      }
    }
  }
  > .rightText {
    width: 588px;
    text-align: start;
    > h3 {
      height: 68px;
      font-weight: 700;
      font-size: 46px;
      color: #484848;
      line-height: 56px;
      padding: 30px 0px;
    }
    > p {
      height: 100px;
      font-weight: 400;
      font-size: 16px;
      color: #9a9a9a;
      line-height: 26px;
      margin-bottom: 10px;
    }
  }
}
.contBox2 {
  width: 1200px;
  padding: 110px 0px 100px;
  margin: auto;
  > h2 {
    text-align: start;
    font-weight: 700;
    font-size: 46px;
    color: #484848;
    margin: 12px 0px 60px;
  }
  > div {
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
}
</style>
